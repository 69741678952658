@font-face {
  font-family: "Veneer Clean Reg It";
  src: url("assets/fonts/VeneerClean-RegIt.woff2") format("woff2"),
    url("assets/fonts/VeneerClean-RegIt.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Veneer Clean Soft";
  src: url("assets/fonts/VeneerClean-Soft.woff2") format("woff2"),
    url("assets/fonts/VeneerClean-Soft.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Veneer Clean Reg";
  src: url("assets/fonts/VeneerClean-Reg.woff2") format("woff2"),
    url("assets/fonts/VeneerClean-Reg.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Veneer Clean Soft It";
  src: url("assets/fonts/VeneerClean-SoftIt.woff2") format("woff2"),
    url("assets/fonts/VeneerClean-SoftIt.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

$primaryColor: #13bbc2;
$accentColor: #2c6feb;

:root {
  --primary-color: #13bbc2;
  --accent-color: #2c6feb;
}

body,
#root {
  min-height: 100vh;
  font-family: "Veneer Clean Reg", sans-serif;
  color: white;
}

body.modal-open {
  overflow: hidden;
}

button {
  border: none;
  border-radius: 20px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
  background-color: var(--primary-color);
  font-family: "Veneer Clean Reg", sans-serif;
  font-size: 1.2em;
  color: white;
  min-width: 120px;
  cursor: pointer;

  &:hover {
    filter: brightness(80%);
  }
}

button.action-primary {
  background-color: var(--accent-color);
  border: white 1px solid;
}

button.action-link {
  color: var(--accent-color);
  border: none;
  box-shadow: none;
  background-color: transparent;
  font-size: 1em;
}

.app-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.main-header {
  background-color: var(--primary-color);
  padding: 15px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 100;
  display: flex;
  justify-content: space-between;

  .logo {
    width: 100px;
    height: auto;
    display: block;
    padding: 0;
    margin: 0;
  }

  button {
    border-radius: 12px;
    margin: 0 5px;
    font-size: 1.3em;

    &.profile-button {
      background-color: white;
      color: var(--primary-color);
    }
  }
}

main.main-content {
  background-image: url(assets/images/webapp-background.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .splash-screen {
    padding: 50px;
    background-color: rgba(255, 255, 255, 0.55);
    margin: 50px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;

    img.splash-logo {
      width: auto;
      height: 300px;
    }

    button {
      margin: 5px;
      max-width: 300px;

      &.action-primary {
        height: 75px;
        font-size: 2em;
        padding: 5px 75px;
      }
    }
  }

  .game-area {
    max-width: 1024px;
    background-color: rgba(255, 255, 255, 0.25);
    height: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 75%;

    .game-area-inner {
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 40px;
      padding: 25px 50px;
      margin: 15px auto;
      width: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &.question-error {
        border: 20px solid #e87879;
      }

      .question-pill {
        background: white;
        color: var(--accent-color);
        border-radius: 25px;
        padding: 5px 50px;
      }

      h2.gif-title {
        color: var(--accent-color);
        font-size: 5em;
        font-family: "Veneer Clean Soft It", sans-serif;
        padding: 0;
        margin: 0;
      }

      img.question-gif {
        border-radius: 12px;
        margin-bottom: 25px;
      }

      .game-buttons-answered {
        display: flex;
        justify-content: space-between;
        width: 400px;
        font-size: 1.5em;
        font-family: "Veneer Clean Soft It", sans-serif;

        .answer {
          display: flex;
          align-items: center;

          &.answer-correct {
            color: var(--accent-color);
          }

          &.answer-incorrect {
            color: red;
          }

          img {
            margin-right: 10px;
          }
        }

        button {
          font-family: "Veneer Clean Soft It", sans-serif;
        }
      }

      .game-buttons button {
        margin: auto 10px;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 250px;

        img {
          height: 50px;
          width: auto;
          display: flex;
          margin: 0 auto;
        }
      }
    }

    .game-area-bottom {
      display: grid;
      grid-template-columns: auto 1fr;
      padding: 0 50px;

      .game-area-bottom-buttons {
        display: flex;
        // justify-content: space-evenly;
        align-items: center;

        button {
          background-color: #1ba1ce;
          // height: 100px;
          padding: 35px;
          margin-right: 25px;
        }
      }

      .game-area-bottom-stats {
        display: flex;
        flex-direction: column;

        .game-stat {
          border-radius: 12px;
          background: white;
          padding: 10px 30px;
          margin-bottom: 10px;
          color: var(--accent-color);
          font-size: 1.6em;
          font-family: "Veneer Clean Soft It", sans-serif;

          &.best-streak {
            color: var(--primary-color);
          }
        }
      }
    }
  }
}

.main-footer {
  background-color: var(--accent-color);
  padding: 10px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    opacity: 0.7;
    font-size: 0.9em;
    display: flex;
    color: white;
    text-decoration: none;
  }

  .rownd-logo {
    width: auto;
    height: 1.2em;
    margin-left: 10px;
  }
}

.modal {
  background: white;
  font-family: Roboto, sans-serif;
  color: black;
  position: fixed;
  max-width: 350px;
  top: 50px;
  margin: 75px 20px auto 20px !important;
  border-radius: var(--rph-container-radius, 15px) !important;
  max-height: calc(100vh - 150px) !important;
  overflow-y: auto !important;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5) !important;
  padding: 32px !important;
  margin-bottom: 50px;

  h2 {
    font-size: 1em;
  }

  .modal-content {
    display: flex;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;
    }

    label {
      font-size: 0.8em;
      color: #666;
      display: block;
      margin-bottom: 5px;
    }

    input {
      border-radius: 12px;
      border: none;
      background-color: #f4f4f4;
      display: block;
      padding: 12px 15px;
      // width: 100%;
      // width: 100%;
    }

    button {
      font-family: Roboto, sans-serif;
      width: 100%;
      font-size: 1em;
      padding: 10px;
      margin: 10px 0;
      box-shadow: none;
    }
  }

  .close {
    width: 12px !important;
    height: 12px !important;
    position: absolute !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    padding: 0 !important;
    margin: 0 !important;
    cursor: pointer !important;
    top: 12px !important;
    right: 12px !important;
    z-index: 9999 !important;

    &::before,
    &::after {
      content: "" !important;
      position: absolute !important;
      width: 100% !important;
      height: 2px !important;
      background-color: #000 !important;
    }

    &::before {
      transform: rotate(45deg) !important;
    }

    &::after {
      transform: rotate(-45deg) !important;
    }
  }
}

.leaderboard.modal {
  background: var(--primary-color);
  font-family: Roboto, sans-serif;
  color: white;
  max-width: 1000px;
  width: 75%;
  bottom: 50px;

  .close {
    color: white;
    width: 32px !important;
    height: 32px !important;
    top: 24px !important;
    right: 24px !important;

    &::before,
    &::after {
      background-color: white !important;
      height: 3px !important;
    }
  }

  h1 {
    color: #fff;
    font-size: 5em;
    font-family: "Veneer Clean Soft It", sans-serif;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  table {
    width: 100%;
    font-family: "Veneer Clean Soft It", sans-serif;
    font-size: 2em;
    text-align: left;
    color: #fff;
    border-collapse: separate;
    border-spacing: 0 5px;

    thead {
      th {
        background-color: darken($primaryColor, 10%);
        color: darken(white, 15%);
      }
    }

    th,
    td {
      padding: 10px 50px;

      &:first-child {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
      }
    }

    td {
      background-color: darken($primaryColor, 5%);
    }
  }
}
